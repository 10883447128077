<template>
  <v-row>
    <v-col cols="12">
      <AccountingItems />
    </v-col>
  </v-row>
</template>

<script>
import AccountingItems from '@/components/admin/partials/Company/Detail/Accounting/AccountingItems'

export default {
  name: 'index',
  components: { AccountingItems },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
