<template>
  <v-card :loading="loading">
    <v-card-title class="px-0 pb-2">
      <v-container class="px-0 py-0 mb-2">
        <v-row align="end" class="px-8">
          <v-col cols="auto">
            <v-btn
              height="29"
              class="label text-capitalize font-weight-regular"
              depressed
              color="secondary"
              :ripple="false"
            >
              {{ $t('page_company_accounting_title') }}
            </v-btn>
          </v-col>

          <v-col cols="auto" class="pl-0">
            <h5 class="text-date font-weight-regular mb-0">
              {{ getCompany.created_at }}
            </h5>
          </v-col>

          <v-col cols="auto">
            <h5 class="text-date font-weight-regular mb-0">
              {{ $t('by', { person: 'Admin' }) }}
            </h5>
          </v-col>

          <v-spacer />
          <v-col cols="2">
            <v-select
              :items="accountItemCategories"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details
              :placeholder="$t('category')"
              v-model="selectedCategory"
              :value="
                accountItemCategories.length ? accountItemCategories[0].id : ''
              "
              @change="filterItems"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              :items="accountItemTypes"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details
              :placeholder="
                accountItemTypes.length ? accountItemTypes[0].name : ''
              "
              v-model="selectedItemType"
              :value="accountItemTypes.length ? accountItemTypes[0].id : ''"
              @change="filterItems"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              :items="accountItemSubTypes"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details
              :placeholder="$t('sub_type')"
              v-model="selectedSubtype"
              @change="filterItems"
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-text class="px-0">
      <Table
        :headers="headers"
        :items="accountItems"
        :footer="false"
        :number-of-pages="1"
        :total-records="getCompanyAccountItems.length"
      >
        <template v-slot:item.type="{ item }">
          <template v-if="item.edit">
            <div class="py-2">
              <v-select
                @change="disableError"
                return-object
                outlined
                dense
                v-model="item.type"
                :items="getAccountItemTypes"
                item-text="name"
                item-value="id"
                :error="typeError"
                :rules="typeRule"
                :hide-details="!typeError"
              >
              </v-select>
            </div>
          </template>
          <template v-else>
            {{ getAccountTypeText(item.type) }}
          </template>
        </template>

        <template v-slot:item.subtype="{ item }">
          <template v-if="item.edit">
            <div class="py-2">
              <v-select
                @change="disableError"
                return-object
                outlined
                dense
                v-model="item.subtype"
                :items="getAccountItemSubtypes"
                item-text="name"
                item-value="id"
                :error="subTypeError"
                :rules="subTypeRule"
                :hide-details="!subTypeError"
              >
              </v-select>
            </div>
          </template>
          <template v-else>
            {{ getAccountSubTypeText(item.subtype) }}
          </template>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn icon @click="toggleEdit(item)">
            <v-icon size="16">
              <template v-if="!item.edit">$edit</template>
              <template v-else>mdi-close</template>
            </v-icon>
          </v-btn>
        </template>
      </Table>
      <v-divider></v-divider>
    </v-card-text>

    <v-card-actions>
      <v-row class="flex-row-reverse">
        <v-col cols="2" class="text-right">
          <v-btn @click="update" color="primary" class="text-capitalize">
            {{ $t('update') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import Table from '@/components/admin/partials/Table/Table'
import { mapGetters } from 'vuex'

export default {
  name: 'AccountingItems',
  components: { Table },
  created() {
    this.loading = true
    this.selectedCategory = null
    this.selectedSubtype = null
    this.loadingMasterData();
    this.getDataFromApi({
      category: this.selectedCategory,
      subtype: this.selectedSubtype
    })
  },
  computed: {
    ...mapGetters([
      'getCompany',
      'getCompanyAccountItems',
      'getAccountItemTypes',
      'getAccountItemSubtypes',
      'getAccountItemCategories'
    ]),
    accountItemCategories() {
      let itemCategories = [...this.getAccountItemCategories]

      itemCategories.unshift({ id: null, name: this.$t('all') })
      return itemCategories
    },

    accountItemSubTypes() {
      let itemSubTypes = [...this.getAccountItemSubtypes]
      itemSubTypes.unshift({ id: null, name: this.$t('all') })
      return itemSubTypes
    },

    accountItemTypes() {
      let itemTypes = [...this.getAccountItemTypes]
      itemTypes.unshift({ id: null, name: this.$t('all') })
      return itemTypes
    },

    accountItems() {
      return this.getCompanyAccountItems.map(item => {
        let obj = Object.assign({}, item)
        obj.edit = false
        obj.edited = false
        return obj
      })
    }
  },
  data() {
    return {
      loading: false,
      accountingItems: [],
      headers: [
        {
          text: this.$t('id'),
          align: 'center',
          value: 'id',
          width: 300,
          sortable: false
        },
        {
          text: this.$t('page_company_accounting_table_header_name'),
          align: 'center',
          value: 'name',
          width: 300,
          sortable: false
        },
        {
          text: this.$t('page_company_accounting_table_header_type'),
          align: 'center',
          value: 'type',
          width: 300,
          sortable: false
        },
        {
          text: this.$t('page_company_accounting_table_header_subtype'),
          align: 'center',
          value: 'subtype',
          width: 300,
          sortable: false
        },
        {
          text: this.$t('actions'),
          align: 'center',
          value: 'action',
          width: 150,
          sortable: false
        }
      ],
      search: '',
      activeItem: null,
      typeError: false,
      typeRule: [v => !!v || this.$t('type_rule')],
      subTypeError: false,
      subTypeRule: [v => !!v || this.$t('sub_type_rule')],
      selectedCategory: null,
      selectedType: null,
      selectedSubtype: null,
      selectedItemType: null
    }
  },
  methods: {
    loadingMasterData() {
      this.$store.dispatch('MASTER_ALL_GET')
    },
    getDataFromApi(params) {
      this.$store
        .dispatch('COMPANY_ACCOUNT_ITEMS_GET', {
          id: this.$route.params.id,
          params: params
        })
        .finally(() => (this.loading = false))
    },
    filterItems() {
      // if(this.selectedCategory && this.selectedSubtype)
      let params = {}
      if (this.selectedCategory !== null)
        params.category = this.selectedCategory
      if (this.selectedSubtype !== null) params.subtype = this.selectedSubtype
      if (this.selectedItemType != null)
        params.type = this.selectedItemType
      this.getDataFromApi(params)
    },
    disableError() {
      if (this.subTypeError) this.subTypeError = false
      if (this.typeError) this.typeError = false
    },
    toggleEdit(item) {
      if (this.activeItem) {
        if (this.activeItem !== item) {
          if (this.activeItem.edit) {
            if (!this.activeItem.subtype || !this.activeItem.type) {
              this.subTypeError = true
              this.typeError = true
              return
            }

            this.activeItem.edit = false
          }
        }
      }

      if (item.edit) {
        if (!item.subtype || !item.type) {
          this.subTypeError = true
          this.typeError = true
          return
        }
      }

      // if (this.activeItem !== item) {
      //   this.activeItem = item
      // } else {
      //   this.activeItem = null
      // }

      item.edited = true
      item.edit = !item.edit
    },

    getAccountTypeText(type) {
      if (type === null || type.id === 'other' || type.id === null)
        return this.$t('other')
      return this.getAccountItemTypes.find(i => i.id === type?.id)?.name
    },

    getAccountSubTypeText(subtype) {
      if (subtype === null) return ''
      return this.getAccountItemSubtypes.find(i => i.id === subtype?.id)?.name
    },

    update() {
      let data = []
      for (const item of this.accountItems) {
        if (item.edited)
          data.push({
            id: `${item.id}`,
            type: item.type.id == 'O' ? null : item.type.id,
            subtype: item.subtype.id ?? this.$t('other')
          })
        item.edit = false
      }
      this.$store.dispatch('COMPANY_ACCOUNT_ITEMS_UPDATE', {
        id: this.$route.params.id,
        data: data
      })
    }
  }
}
</script>

<style lang="scss" src="./AccountingItems.scss" scoped></style>
